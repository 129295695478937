import React from 'react';
import { graphql } from 'gatsby';
import * as Ink from '@carta/ink';

import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import PreviewCard from '../components/Common/PreviewCard';

const FormatterComponents = ({
  data: {
    formatterComponents: { nodes },
  },
}) => {
  return (
    <Layout pageTitle="Formatter Components">
      <Header title="Formatter" />

      <Ink.Tiles columns={[1, 1, 2, 2, 3]}>
        {nodes.map(component => (
          <PreviewCard
            title={component.displayName}
            text={component.purpose}
            actionLabel="View details"
            actionHref={`/components/${component.displayName}`}
            previewWithCheckerboard
            previewCode={component.samples && component.samples.length > 0 && component.samples[0].code}
          />
        ))}
      </Ink.Tiles>
    </Layout>
  );
};

export default FormatterComponents;

export const query = graphql`
  query FormatterComponentsQuery {
    formatterComponents: allComponentsJson(filter: { categories: { in: "formatter" } }, sort: { fields: position }) {
      nodes {
        displayName
        purpose
        status
        categories
        aka
        samples {
          environments
          code
        }
      }
    }
  }
`;
